.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
html, body {
  overscroll-behavior-x: none;
  min-height: -webkit-fill-available;
  padding-top: env(safe-area-inset-top);
}

.typewriter{
  font-family: Cormorant;
  font-size: medium;
}

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');

.gallery-item {
  margin: 5px;
}


.MuiDrawer-paper {
  width: 100% !important;
  }

  .swipe-hint {
    opacity: 1;
    
    transition: max;
    transition: opacity 0.5s ease; /* Adjust the duration and timing function as needed */
  }
  .swipe-hint.hidden{
    opacity: 0;
    height: 0;
  }

  .arrow-animate{
   
  }
  
  @keyframes oscillate {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(50px);
    }
  }

/* Hide scrollbar for IE, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

@font-face {
  font-family: 'DELICATE_1';
  src: local('DELICATE_1'), url(./fonts/delicate_1.ttf) format('opentype');
}
@font-face {
  font-family: 'YESIVA_1';
  src: local('YESIVA_1'), url(./fonts/yesiva_1.ttf) format('opentype');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




* {
  user-select: none;
}


.story_time{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Karla", sans-serif;
  height: 100vh;
  width: 100vw;
  background-color: black;
  position:absolute;
  top:0;
    overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;

}

.story_time * img{
  width: 100vw !important;
  max-height: 100vh !important;
  height: -webkit-fill-available;
  position: fixed;
  top: 0px;
  margin: 0;
  padding: 0;
  left : 0px;
}

.app {
  overflow: hidden;
}

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row>* {
  margin: 5px;
}

h1 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.050);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
  color : "red"
}

.cardContainer {
  width: 100%;
  max-width: 260px;
  height: 350px;
}

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 260px;
  height: 300px;
  box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.1);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}



.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

video{
  width: 100vw !important;
  max-height: 100vh !important;
  height: -webkit-fill-available;
  position: fixed;
  top: 0px;
  margin: 0;
  padding: 0;
  left : 0px;
}

.music-panel-mini{
  z-index: 100;
}

.music-panel-expand{
  z-index: 100000;
  font-family: Cormorant !important;
  color: blue !important;
  @primary-color: blue;
}
.light-theme svg{
  color:#C6A755 !important;
}

.react-jinke-music-player-mobile-header-title {
  color: #C6A755;
  font-weight: 800;
}
.rc-slider-track{
  color:#C6A755 !important;
  background-color: #C6A755 !important;
}
.react-jinke-music-player-mobile-play-model-tip{
  background-color: #5882BB !important;
}

.rc-slider-handle {
  color:#C6A755 !important;
  background-color: #C6A755 !important; 
}

.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 625px) {
  .buttons {
    flex-direction: column;
  }
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.0);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0%   { transform: scale(1,1) }
  10%  { transform: scale(1.1,1.1) }
  30%  { transform: scale(.9,.9) }
  50%  { transform: scale(1,1) }
  57%  { transform: scale(1,1) }
  64%  { transform: scale(1,1) }
  100% { transform: scale(1,1) }
}